<template>
    <modal ref="modalConfirmarPrecio" titulo="Confirmar precio del producto" no-aceptar adicional="Confirmar" tamano="modal-xl" icon="aprobar" @adicional="onSave()">
        <div class="row mx-0 px-5">
            <div class="col d-flex">
                <img :src="data.foto" width="77" height="77" class="obj-cover br-8 border" />
                <div class="col pr-0 f-14">
                    <p class="text-general f-600 tres-puntos">
                        {{ _.get(data, 'producto_nombre') }}
                    </p>
                    <p class="my-2 text-general">
                        {{ _.get(data, 'presentacion') }}
                    </p>
                    <p class="my-2 text-general">
                        <b>Costo: </b> ${{ _.get(data, 'unidad_costo') }}
                    </p>
                </div>
            </div>
            <div class="col-3 f-14">
                <p class="text-general">
                    <b>Compra:</b>  {{ _.get(data, 'unidades') }}
                </p>
                <p class="text-general my-2">
                    <b>Pedido:</b>  {{ _.get(data,'pedido') }}
                </p>
                <p class="text-general my-2">
                    <b>Entrega:</b>  {{ _.get(data,'fecha') }}
                </p>
            </div>
            <div class="col d-flex">
                <img :src="data.delivery_foto" width="77" height="77" class="obj-cover br-8 border" />
                <div class="col pr-0 f-14">
                    <p class="text-general f-600">
                        Delivery
                    </p>
                    <p class="my-2 text-general2">
                        {{ _.get(data, 'delivery_nombre') }}
                    </p>
                    <p class="my-2 text-general2">
                        {{ _.get(data, 'cedis_nombre') }}
                    </p>
                </div>
            </div>
        </div>
        <div class="row mx-0 py-3">
            <div class="col-auto px-5" />
            <div class="col-auto px-4" />
            <div class="col-3">
                <p class="pl-3 f-14 text-general">
                    Costo
                </p>
                <input-miles v-model="costo" class="w-100" style="font-size:14px;" />
            </div>
            <div class="col-4">
                <p class="pl-3 f-14 text-general">
                    Proveedor
                </p>
                <el-select v-model="data.proveedor_id" placeholder="Buscar proveedor" class="w-100">
                    <el-option
                    v-for="(item, i) in proveedores"
                    :key="i"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapGetters } from 'vuex'
import cedisProductos from '~/services/cedis/cedis_productos'
import confirmacionPrecio from '~/services/retiros/confirmacion_precio';
export default {
    data(){
        return{
            url_producto: 'https://d1.com.co/wp-content/uploads/2021/04/12000412.jpg',
            url_imagen: 'https://cdnb.20m.es/sites/112/2019/04/cara6-620x618.jpg',
            options: [{
                value: 'Option1',
                label: 'Option1'
            }, {
                value: 'Option2',
                label: 'Option2'
            }, {
                value: 'Option3',
                label: 'Option3'
            }, {
                value: 'Option4',
                label: 'Option4'
            }, {
                value: 'Option5',
                label: 'Option5'
            }],
            value: '',
            data: {},
            proveedores: [],
            costo: ''
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
        }),
      
    },
    methods: {
        async toggle(data){
            this.data = _.cloneDeep(data)
            this.costo = this.data.unidad_costo.toString().replace('.', '')
            await this.listaProveedores()
            this.$refs.modalConfirmarPrecio.toggle();
        },
        async listaProveedores(){
            try {
                const params = {
                    id_cedis: this.id_cedis
                }
                const { data } = await cedisProductos.listaProveedores(params);
                this.proveedores = _.uniqBy(data.proveedores, 'id')
            } catch (error){
                this.error_catch(error)
            }
        },
        async onSave(){
            try {
                if(this.costo <= 0) return this.notificacion('', 'El costo debe ser mayor a cero (0)', 'error')
                if(!this.data.proveedor_id) return this.notificacion('', 'Debe seleccionar a un proveedor', 'error')
                const params = {
                    id_admin_pedido_producto: this.data.id,
                    unidad_costo: this.costo,
                    id_proveedor: this.data.proveedor_id
                }
                const { data } = await confirmacionPrecio.confirmar(params)

                if (data.hasOwnProperty('exito')){
                    this.notificacion('', 'Producto confirmado correctamente', 'success')
                    this.$refs.modalConfirmarPrecio.toggle();
                    this.$emit('saved')
                }

                console.log({params});
            } catch (error){
                this.error_catch(error)
            }
        }
    }
}
</script>
